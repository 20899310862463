import { Avatar, Txt, VStack, Button, HStack } from "@pomebile/primitives"
import {
  ActivityIcon,
  DollarIcon,
  DollarSquircleIcon,
  GCashMiniIcon,
} from "@pomebile/design-system"
import { StickyBottom } from "../components/StickyBottom"
import { ScreenFrame } from "../components/ScreenFrame"
import { AppDownloadQRForDesktop } from "../components/AppDownloadQRForDesktop"
import { deeplink } from "../utils/deeplink"
import { onlyShowOnMobileStyles } from "../sharedStyles.css"

interface Item {
  icon: JSX.Element
  title: string
  subtitle?: string
}

interface CardActivationDownloadProps {
  accountType: "RPC" | "NRAU"
}

export function CardActivationDownload({ accountType }: CardActivationDownloadProps) {
  const downloadType =
    accountType === "RPC" ? "CardActivationRPCDownloadLink" : "CardActivationNRAUDownloadLink"

  const handleDownloadPress = () => {
    const link = deeplink[downloadType].link
    window.open(link, "_blank")
  }

  let items: Item[]
  if (accountType === "RPC") {
    items = [
      {
        icon: <GCashMiniIcon width={29} height={24} fill="icon-brand" />,
        title: "Send money to GCash",
        subtitle: "Make GCash transfers to anyone in the Philippines.",
      },
      {
        icon: <ActivityIcon width={32} height={32} fill="icon-brand" />,
        title: "See your activity",
        subtitle: "Stay on top of all your transactions and payments.",
      },
      {
        icon: <DollarSquircleIcon width={27.27} height={27.27} fill="icon-brand" />,
        title: "Payoff your balance",
        subtitle: "View your balance and statements, make a payment, and set up autopay.",
      },
    ]
  } else {
    items = [
      {
        icon: <DollarIcon width={32} height={32} fill="icon-brand" />,
        title: "See how much you can spend",
      },
      {
        icon: <ActivityIcon width={32} height={32} fill="icon-brand" />,
        title: "See all your Pomelo Mastercard® transactions in one place",
      },
    ]
  }

  return (
    <ScreenFrame>
      <VStack height="full" justifyContent="space-between">
        <VStack height="full" gap="xl2" padding={{ left: "lg", right: "lg" }}>
          <VStack alignItems="center">
            <Txt variant="headline2" as="h1" textAlign="center">
              Download the Pomelo app to continue
            </Txt>
          </VStack>

          <VStack gap="xl2">
            {items.map(({ icon, title, subtitle }, index) => (
              <HStack key={index} alignItems="center" gap="md">
                <Avatar size="lg" svg={icon} />
                <VStack>
                  <Txt variant="subtitle2">{title}</Txt>
                  {!!subtitle && <Txt variant="body2">{subtitle}</Txt>}
                </VStack>
              </HStack>
            ))}
          </VStack>

          <AppDownloadQRForDesktop type={downloadType} />
        </VStack>

        <div className={onlyShowOnMobileStyles}>
          <StickyBottom>
            <VStack gap="sm">
              <Button onClick={handleDownloadPress}>Download the Pomelo App</Button>
            </VStack>
          </StickyBottom>
        </div>
      </VStack>
    </ScreenFrame>
  )
}
