import { AlertIcon } from "@pomebile/design-system"
import { Avatar, Txt, VStack, Button, Secure } from "@pomebile/primitives"
import { formatPhoneNumber } from "../utils/format"
import { Link } from "@pomebile/primitives-web"
import { ScreenFrame } from "../components/ScreenFrame"
import { StickyBottom } from "../components/StickyBottom"
import { Country } from "../api/webRoutes"
import {
  CARD_ACTIVATION_NRAU_WEBSITE,
  CARD_ACTIVATION_RPC_WEBSITE,
  MARKETING_WEBSITE,
} from "../envConstants"

export interface AccountNotFoundProps {
  accountType: "RPC" | "NRAU"
  country: Country
  phoneNumber: string
}

export function AccountNotFound({ accountType, country, phoneNumber }: AccountNotFoundProps) {
  const formattedPhoneNumber = `${country === "US" ? "+1" : "+63"}-${formatPhoneNumber(
    phoneNumber,
    "-",
  )}`
  const headline = accountType === "RPC" ? "Unable to sign in" : "Account not found"
  const body =
    accountType === "RPC"
      ? `We were unable to sign you in using ${formattedPhoneNumber}.`
      : `We were unable to find an account associated with ${formattedPhoneNumber}.`

  return (
    <ScreenFrame>
      <VStack justifyContent="space-between" height="full">
        <VStack gap="md" justifyContent="center" alignItems="center" height="full">
          <Avatar
            size="lg"
            variant="error"
            svg={<AlertIcon width={34} height={34} fill="icon-error" />}
          />
          <VStack gap="xs">
            <Txt variant="headline2" as="h1" textAlign="center">
              {headline}
            </Txt>
            <Secure>
              <Txt variant="body1" textAlign="center">
                {body}
              </Txt>
            </Secure>
          </VStack>
        </VStack>

        <StickyBottom>
          <VStack gap="sm">
            <Link url={MARKETING_WEBSITE}>
              <Button onClick={() => {}}>Sign Up</Button>
            </Link>
            <Link
              url={
                accountType === "RPC" ? CARD_ACTIVATION_RPC_WEBSITE : CARD_ACTIVATION_NRAU_WEBSITE
              }
            >
              <Txt textAlign="center" color="text-brand" as="p">
                Back to Sign In
              </Txt>
            </Link>
          </VStack>
        </StickyBottom>
      </VStack>
    </ScreenFrame>
  )
}
