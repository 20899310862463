// Route elements
import { Phone } from "./screens/Phone.tsx"
import { OTPScreen } from "./screens/OTP.tsx"
import { AppEvent, AppScreen, AppState, calculateScreen } from "./cardActivationModel.ts"
import { match } from "@pomebile/shared/tagged-union"
import { AuthContext, AuthData } from "./api/authContext.ts"
import { DEBUG_RESTORE_KEY, DEBUG_STORAGE_KEY } from "./components/DevTools.tsx"
import { anonymousIdPromise } from "./utils/segment.ts"
import {
  InitialData,
  ShellState,
  createAuthContext,
  createLoggingContext,
} from "./sharedShellLogic.tsx"
import { ActivateCard } from "./screens/ActivateCard.tsx"
import { AccountNotFound } from "./screens/AccountNotFound.tsx"
import { CardActivationDownload } from "./screens/CardActivationDownload.tsx"
import {
  AppApiContext,
  activateCard,
  authenticateSmsOtp,
  generateSmsOtp,
} from "./api/webRoutes.tsx"
import { GeneralErrorScreen } from "./screens/GeneralError.tsx"
import { ErrorData } from "./api/errorContext.ts"

export type Progression = "apply" | "accept-plan" | "download-app" | "none"

export const calculateProgression = (): Progression => "none"

export const renderScreen = (
  screen: AppScreen,
  send: (ev: AppEvent) => void,
  apiCx: AppApiContext,
  authCx: AuthContext,
): JSX.Element =>
  match(
    screen,
    {
      Phone: () => (
        <Phone
          country={apiCx.accountType === "RPC" ? "US" : undefined}
          onDone={(country, phoneNumber) =>
            send(AppEvent.PhoneNumberSubmitted({ country, phoneNumber }))
          }
        />
      ),

      OTP: ({ country, phoneNumber }) => (
        <OTPScreen
          api={{
            requestOTPCode: () => generateSmsOtp(apiCx, country, phoneNumber),
            verifyOTPCode: (code) => authenticateSmsOtp(code, country, phoneNumber, apiCx),
          }}
          country={country}
          phoneNumber={phoneNumber}
          onDone={(authResult) => {
            const { tag } = authResult
            if (tag === "disabledUser") {
              // Intentionally vague so users don't know we've labelled them as fraud/disabled
              // AuthenticationException = disabled user (could be either fraud or non-fraud)
              send(
                AppEvent.EncounteredGeneralError({
                  errorType: "cardActivationAuthenticationException",
                }),
              )
            } else {
              send(AppEvent.AuthCompleted({ authResult }))
            }
          }}
        />
      ),

      ActivateCard: ({ cardIdent, selectedCardColor, productType, auth }) => (
        <ActivateCard
          api={{
            activateCard: async (cvcNumberVgsToken, expirationDate) => {
              await activateCard(cardIdent, cvcNumberVgsToken, expirationDate, apiCx, auth, authCx)
            },
          }}
          cardColor={selectedCardColor}
          productType={productType}
          onDone={() => send(AppEvent.CardActivated())}
        />
      ),

      AccountNotFound: ({ country, phoneNumber }) => (
        <AccountNotFound
          accountType={apiCx.accountType}
          country={country}
          phoneNumber={phoneNumber}
        />
      ),

      DownloadApp: () => <CardActivationDownload accountType={apiCx.accountType} />,
    },

    // There shouldn't ever be a case here, but just in case...
    (_) => <GeneralErrorScreen />,
  )

export function initCardActivation(
  apiCx: AppApiContext,
  isDevToolsEnabled: boolean,
): InitialData<AppState, AppScreen> {
  // TODO next person to add a logger must convert this to an array

  const logger = createLoggingContext()

  if (isDevToolsEnabled) {
    const restore = localStorage.getItem(DEBUG_RESTORE_KEY) === "yes"
    localStorage.removeItem(DEBUG_RESTORE_KEY)

    if (restore) {
      const serialized = localStorage.getItem(DEBUG_STORAGE_KEY)
      if (serialized) {
        const { state, apiCx }: { state: AppState; apiCx: AppApiContext } = JSON.parse(serialized)

        return {
          initialState: { state, screens: [calculateScreen(state)] },
          apiCx,
          authCx: createAuthContext(apiCx),
          logging: createLoggingContext(),
        }
      }
    }
  }
  const initialState: ShellState<AppState, AppScreen> = {
    state: AppState.Initial({
      country: apiCx.accountType === "RPC" ? "US" : undefined,
      phoneNumber: undefined,
      activateCardState: undefined,
      cardActivated: false,
    }),
    screens: [AppScreen.Phone()],
  }

  anonymousIdPromise?.then((id) => {
    apiCx.anonymousId = id
  })

  return {
    initialState,
    apiCx,
    authCx: createAuthContext(apiCx),
    logging: createLoggingContext(),
  }
}

export const getAuth = (state: AppState): AuthData | undefined => {
  return match(
    state,
    {
      Initial: () => undefined,
      AccountNotFound: () => undefined,
      GeneralError: (): AuthData | undefined => undefined,
    },
    ({ data: { auth } }) => auth,
  )
}

export const getError = (event: AppEvent): ErrorData | undefined => {
  return match(
    event,
    {
      EncounteredGeneralError: ({ errorType }): ErrorData | undefined => ({ errorType }),
    },
    () => undefined,
  )
}
