import { CheckIcon } from "@pomebile/design-system"
import { Avatar, Button, Txt, VStack } from "@pomebile/primitives"
import { Dialog } from "../components/Dialog"

interface CardActivatedDialogProps {
  productType: "unsecured" | "secured"
  isOpen: boolean
  onContinue: () => void
}

export function CardActivatedDialog({ productType, isOpen, onContinue }: CardActivatedDialogProps) {
  return (
    <Dialog open={isOpen} onClose={() => {}}>
      <VStack padding="xl" gap="xl" justifyContent="center" alignItems="center">
        <VStack gap="xs">
          <VStack gap="sm" justifyContent="center" alignItems="center">
            <Avatar size="md" variant="success" svg={<CheckIcon fill="icon-success" />} />
            <Txt textAlign="center" variant="headline3">
              Card activated
            </Txt>
          </VStack>
          <Txt textAlign="center" variant="body2">
            You may use your Pomelo {productType === "secured" ? "Secured" : ""} Mastercard® to
            spend in stores and online. Shop anywhere Mastercard® is accepted.
          </Txt>
        </VStack>
        <Button web-type="button" onClick={onContinue}>
          Continue
        </Button>
      </VStack>
    </Dialog>
  )
}
